

.nav-bar{
    width:100%;
    float:left;
    background-color:black;
    padding-top:10px;
    padding-bottom: 15px;
    font-family: 'Lato', sans-serif;
   
}

.my-logo{
    width:25%;
    float:left;
    font-family: 'Dancing Script', cursive;
    text-align: center;
    padding-top:5px;
    font-size:20px;
    img{
        width:32px;
    }
    

}

.nav-link{
    width:75%;
    float:left;
    text-align:right;
    padding-top:7px;
    font-size: 20px;
    padding-right:0px;
    
    
 a{
     
    color:white;
    padding-right: 0px;
    font-weight: 400;
    text-decoration: none;
    font-size:16px;
    
    padding-right: 20px;
    padding-left: 20px;
    
}
   
}



.nav-link a:hover{

color:#009e66;
    transition:0.5s;
  
    
}



 

  


.footer{
    width:100%;
    float:left;
    font-family: 'Lato', sans-serif;  
    background-color: black;
         padding-bottom:10px; 
        padding-top: 10px;
}

.developer{
    width:100%;
    float:left;
    margin-top: 10px;

    padding-bottom:10px;
    padding-top: 15px;
    text-align: center;
    font-size: 13.6px;
    color:#9f9f9f;
    a{
        color: #9f9f9f;
        font-size: 13.6px;
        text-decoration: none;
    }
}













