$heading-color: #009e66;

.home-content {
  width: 100%;
  float: left;
}
.personalintro {
  width: 100%;
  float: left;
  font-family: "K2D", sans-serif;
  background-image: url("../Assests/Images/amit.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.colorlayer {
  width: 100%;
  float: left;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
}

.personalintrotext {
  width: 100%;
  float: left;
}

.my-name {
  color: #fff;
  width: 100%;
  font-size: 70px;
  font-weight: 700;
  letter-spacing: 1px;
  padding-bottom: 34px;
  padding-top: 30vh;
  padding-left: 30px;
}

.profession {
  width: 100%;
  float: left;

  padding-left: 40px;
}

.wrapper {
  display: inline-flex;
  .static-text {
    font-size: 32px;
    color: #fff;
    font-weight: 400;
    letter-spacing: 0.3px;
  }
}

.wrapper {
  .dynamic-text {
    margin-left: 12px;
    height: 48px;
    line-height: 48px;

    overflow: hidden;
    li {
      list-style: none;
      font-size: 32px;
      font-weight: 500;
      color: #fff;
      position: relative;
      top: -2px;
      animation: slide 6s steps(3) infinite;
      span {
        position: relative;
      }
    }
  }
}

.dynamic-text li span:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;

  animation: typing 2s steps(19) infinite;
}

@keyframes slide {
  100% {
    top: -147px;
  }
}

@keyframes typing {
  100% {
    left: 100%;
    margin: 0 -35px 0 35px;
  }
}

.resume-button {
  width: 20%;
  float: left;
  margin-left: 20px;
  margin-top: 20px;
}

.why-me {
  width: 100%;
  float: left;
 
  font-family: "K2D", sans-serif;
  color: #9f9f9f;
  p {
    font-weight: 200;
  }
}

.reason-skill {
  width: 50%;
  float: left;
  margin-top: 40px;
  margin-left: 30px;
  padding: 30px 10px 10px 20px;

  word-spacing: 2px;

  h1 {
    line-height: 40px;
    margin-bottom: 20px;
    span {
      color: #009e66;
    }
  }
  p {
    margin-bottom: 20px;
  }
}

.homebtn {
  margin: 20px 0px 0px 0px;
  background-color: #009e66;
  border: 1px solid #009e66;
  color: #fff;
  border-radius: 30px;
  padding: 10px 35px;
  font-size: 14px;
  a{
    text-decoration: none;
    color:#fff;
  }
}

.reason-skillshow {
  width: 45%;
  float: left;
  margin-top: 30px;
  margin-left: 30px;

  word-spacing: 2px;
}

.skill-showboxcolumn1 {
  width: 50%;
  float: left;
}

.skill-showboxcolumn2 {
  width: 50%;
  float: left;
  height: 250px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.rsbox1 {
  width: 90%;
  float: left;

  padding-right: 10px;
  padding-bottom: 10px;
  .skill-icon {
    color: #009e66;
    font-size:32px;
    margin-bottom: 8px;
    padding-left: 2px;
    font-weight: 200;
  }
  h2 {
    margin-bottom: 10px;
  }
}

.rsbox2 {
  width: 90%;
  float: left;
  margin-top: 30px;
  padding-right: 10px;
  padding-bottom: 10px;

  .skill-icon {
    color: #009e66;
    font-size:32px;
    margin-bottom: 8px;
    padding-left: 2px;
    font-weight: 400;
  }
  h3 {
    margin-bottom: 10px;
  }
}

.about-content {
  width: 100%;
  float: left;
  background: black;
  font-family: "K2D", sans-serif;
  color: #9f9f9f;
}

.about-heading {
  color: white;
  padding-left: 1px;

  padding-top: 30px;
  text-align: center;
  font-size: 35px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 5px;
}

.bottom-border {
  width: 80px;
  margin: 0 auto;
  background-color: #009e66;
  height: 2px;
  margin-top: 15px;
}

.overlay-aboutimage{
  width:100%;
  float:left;

  height:500px;
  background-color:rgba(0, 0, 0, 0.5);
}
.about-image {
  width:45%;
  float: left;
  margin-left: 30px;
  margin-top: 30px;
  background-image: url("../Assests/Images/myimage.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
}

.about-text {
  width: 45%;
  float: left;
  margin-left: 30px;

  margin-top: 30px;

  h2 {
    color: #009e66;
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: 400;
  }
  p {
    font-size: 15px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    padding-bottom: 29px;
    text-align: justify;
    padding-right: 10px;
    margin-bottom: 16px;
  }
}

.info-data {
  font-size: 15px;
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;

  color: #9f9f9f;

  th {
    padding: 9px 10px 9px 0;
    color: #009e66;
  }
  tr {
    color: white;
    a {
      text-decoration: none;
      color: #1ab69d;
    }
  }
}

.info-data th:first-child {
  letter-spacing: 1px;

  font-weight: 500;
  text-transform: uppercase;
}

.content-title {
  font-size: 31px;
  font-weight: 700;
  line-height: 1.6;
  margin-bottom: 16px;
  color: #9f9f9f;
}

button {
  margin: 15px;
  background-color: #009e66;
  border: 1px solid #009e66;
  color: #fff;
  border-radius: 30px;
  padding: 10px 35px;
  font-size: 14px;
}

.portfolio-content {
  width: 100%;
  float: left;
  background: black;
  font-family: "K2D", sans-serif;
  color: #9f9f9f;
}

.portfolio-heading {
  color: white;
  padding-left: 1px;
  margin-bottom: 5px;
  padding-top: 30px;
  text-align: center;
  font-size: 35px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
}

.portfolio-images {
  width: 100%;
  float: left;
  padding-top: 30px;
 
  
}
.portfolio-image {
  width: 32%;
  float: left;
  margin-left: 13px;
  margin-top: 20px;
  height: 280px;
  text-align: center;
  border-radius:4px;
  border-radius:2px solid white;
  border-radius: 15px;
  position: relative;
  transform:scale(.85) ;
  transition: all .3s ease-in-out;
 

  img {
    width:100%;
    height:200px;
    margin-top: 12px;
    margin-bottom: 12px;
   
  }
  a{
    text-decoration: none;
    color:#9f9f9f
  }

}

.portfolio-image:hover{
  
  transform:scale(0.95);
  cursor:pointer;
}

.portfolio-image:hover{
  a{
    color:#009e66;
  }
}



.skill-content {
  width: 100%;
  float: left;
  font-family: "K2D", sans-serif;
  color: #9f9f9f;
  background: black;
}

.skill-show {
  width: 50%;
  margin: 0 auto;
}

.skill-details {
  width: 100%;

  margin-left: 12px;
  margin-right: 12px;
  margin-top: 35px;
  padding: 10px 20px;
  margin-bottom: 35px;

  position: relative;
  border-radius: 7px;
}

.title-text {
  padding-left: 20px;

  padding-top: 35px;

  margin-bottom: 5px;
  font-size: 35px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0 auto;
  width: 220px;
  color: white;
}

.skill-box {
  width: 100%;
  margin: 15px 0;
  .skill-name {
    display: block;
    font-size: 18px;
    color: #9f9f9f;
    font-weight: 600;
  }
}

.skill-bar {
  height: 8px;
  width: 100%;
  border-radius: 6px;
  background-color: #9f9f9f;
  margin-top: 6px;
}

.skill-per {
  position: relative;
  display: block;
  height: 100%;
  width: 95%;
  border-radius: 6px;
  background-color: $heading-color;
  opacity: 0;
  animation: progress 0.4s ease-in-out forwards;
}

.skill-per.css {
  width: 80%;
  animation-delay: 0.1s;
}

.skill-per.bootstrap {
  width: 85%;
  animation-delay: 0.15s;
}

.skill-per.figma {
  width: 65%;
  animation-delay: 0.2s;
}

.skill-per.javascript {
  width: 70%;
  animation-delay: 0.25s;
}

.skill-per.jquery {
  width: 65%;
  animation-delay: 0.3s;
}

.skill-per.react {
  width: 50%;
  animation-delay: 0.35s;
}

@keyframes progress {
  0% {
    width: 0;
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.skill-per .tooltip {
  position: absolute;
  right: -14px;
  top: -28px;
  font-size: 16px;
  font-weight: 500;
  color: $heading-color;
  padding: 2px 6px;
  border-radius: 3px;
  z-index: 1;
}

.tooltip::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -2px;
  height: 10px;
  width: 10px;
  z-index: -1;
  transform: translate(50%) rotate(45deg);
}

.contact-content {
  width: 100%;
  float: left;
  font-family: "K2D", sans-serif;
  color: #9f9f9f;
  background: black;

  .contact-heading {
    color: white;
    padding-left: 1px;
    margin-bottom: 5px;
    padding-top: 30px;
    text-align: center;
    font-size: 35px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
  }
}

.contact-info {
  width:100%;
  float: left;
 

  margin-top: 30px;
  padding-top: 20px;
  padding-left: 40px;
}

.box {
   width:30%;
   float:left;
  padding: 20px 40px;
  margin: 10px;
   text-align: center;

  .icon {
    width: 60px;
    height: 60px;
    background-color: $heading-color;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 22px;
    color: white;
    margin:0 auto;
  }
}

.text {
  display: flex;
  margin-left: 20px;
  font-size: 16px;
  flex-direction: column;
  font-weight: 300;
  color: #9f9f9f;
  margin-top: 20px;
  h3 {
    font-weight: 500;
  }
}

.map {
  width:100%;
  float: left;
  text-align: center;
  margin-top: 30px;

  padding-top: 10px;
}
